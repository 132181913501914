<div class="login-container ">
    <div class="main_container">
        <img src="/assets/sensformer.jpg" alt="sensformerImg" class="sensformerImg" />
        <img class="logo" src="/assets/logo.png" alt="logo" />
        <div class="container second_container mobileViewLogin">
            <div class="container" style=" background: #FFFFFF 0% 0% no-repeat padding-box;
                
                      opacity: 1">
                <div style="margin-top: 15px;
      margin-bottom: 15px;">
                    <h1 class="mt-3 mb-3 " style="text-align: left;
        font: Bold 27px/27px Siemens-font-Family;
        letter-spacing: 0px;
        color: #2D373C;
        opacity: 1;left: 754px; margin-left: 10px;">Sensproducts Cloud</h1>
                    <div class="form-check ml-1" style="margin-left: 25px;">
                        <div class="ml-3 form-check">
                            <label class="form-check-label termsChecklabel" for="check7">
                <a style="text-decoration : none"
                  href="../assets/docs/Sensproducts and Consulting Services_APAC and PR China Digital Service Master Terms August2020.pdf"
                  target="_blank">
                  Master Terms & Conditions Cloud - Asia Pacific & China Region
                </a>
              </label>
                        </div>
                        <hr style="width:50%;text-align:left;margin-left:0;background-color: #BECDD7;">
                        <div class="ml-3 form-check">
                            <label class="form-check-label termsChecklabel" for="check7">
                              <a style="text-decoration : none"
                              href="../assets/docs/Sensproducts and Consulting Services_EMEA Region_Digital Service Master Terms August2020.pdf"
                              target="_blank">
                  Master Terms & Conditions Cloud - Europe,Middle East & Africa Region
                </a>
              </label>
                        </div>
                        <hr style="width:50%;text-align:left;margin-left:0">
                        <div class="ml-3 form-check">
                            <label class="form-check-label termsChecklabel" for="check7">
                              <a style="text-decoration : none"
                              href="../assets/docs/Sensproducts and Consulting Services_Americas_Digital Service Master Terms August2020.pdf"
                              target="_blank">
                  Master Terms & Conditions Cloud - Americas Region
                </a>
              </label>
                        </div>
                        <hr style="width:75%;text-align:left;margin-left:0;border-top: 3px solid #6e8ba1;">
                        <!-- <hr style="width: 75%; text-align: left; margin-left: 0;margin-top: 2px;margin-bottom: 2px;border: 2px solid;"></hr> -->
                        <div class="ml-3 form-check">
                            <label class="form-check-label termsChecklabel" for="check7">
              <a style="text-decoration : none"
                href="https://aws.amazon.com/service-terms/" target="_blank">
                AWS Terms & Conditions
              </a>
            </label>
                        </div>

                        <hr style="width:50%;text-align:left;margin-left:0">
                        <!-- <hr style="width: 75%; text-align: left; margin-left: 0;margin-top: 2px;margin-bottom: 2px;border: 2px solid;"></hr> -->
                        <div class="ml-3 form-check">
                            <label class="form-check-label termsChecklabel" for="check7">
                              <a style="text-decoration : none"
                              href="../assets/docs/Sensformer_AcceptableUsePolicy v1.0.pdf"
                              target="_blank">
                          Acceptable Use Policy
                        </a>
                      </label>
                        </div>
                        <hr style="width:50%;text-align:left;margin-left:0">
                        <!-- <hr style="width: 75%; text-align: left; margin-left: 0;margin-top: 2px;margin-bottom: 2px;border: 2px solid;"></hr> -->
                        <div class="ml-3 form-check">
                            <label class="form-check-label termsChecklabel" for="check7">
                              <a style="text-decoration : none"
                              href="../assets/docs/Data_Privacy_Terms_alt.pdf"
                              target="_blank">
                          Data Privacy
                        </a>
                      </label>
                        </div>
                    </div>

                </div>
                <div style="margin-top: 30px;
                margin-bottom: 15px;">
                    <h1 class="mt-3 mb-3 " style="text-align: left;
                  font: Bold 27px/27px Siemens-font-Family;
                  letter-spacing: 0px;
                  color: #2D373C;
                  opacity: 1;left: 754px; margin-left: 10px;">Sensproducts Edge</h1>
                    <div class="form-check ml-1" style="margin-left: 25px;">
                        <div class="ml-3 form-check">
                            <label class="form-check-label termsChecklabel" for="check7">
                          <a style="text-decoration : none"
                            href="../assets/docs/Master_Agreement_APAC_v2.0_rev00.pdf"
                            target="_blank">
                            Master Terms & Conditions Edge - Asia Pacific & China Region
                          </a>
                        </label>
                        </div>
                        <hr style="width:50%;text-align:left;margin-left:0;background-color: #BECDD7;">
                        <div class="ml-3 form-check">
                            <label class="form-check-label termsChecklabel" for="check7">
                              <a style="text-decoration : none"
                              href="../assets/docs/Master_Agreement_EMEA_v2.0_rev03.pdf"
                              target="_blank">
                            Master Terms & Conditions Edge - Europe,Middle East & Africa Region
                          </a>
                        </label>
                        </div>
                        <hr style="width:50%;text-align:left;margin-left:0">
                        <div class="ml-3 form-check">
                            <label class="form-check-label termsChecklabel" for="check7">
                              <a style="text-decoration : none"
                              href="../assets/docs/Master_Agreement_AMERICAS_v2.0_rev00.pdf"
                              target="_blank">
                            Master Terms & Conditions Edge - Americas Region
                          </a>
                        </label>
                        </div>
                        <hr style="width:75%;text-align:left;margin-left:0;border-top: 3px solid #6e8ba1;">
                        <!-- <hr style="width: 75%; text-align: left; margin-left: 0;margin-top: 2px;margin-bottom: 2px;border: 2px solid;"></hr> -->
                        <div class="ml-3 form-check">
                            <label class="form-check-label termsChecklabel" for="check7">
                              <a style="text-decoration : none"
                              href="../assets/docs/Supplement_Edge_Services_1.0_rev03.pdf"
                              target="_blank">
                          Supplement Edge
                        </a>
                      </label>
                        </div>
                        <hr style="width:50%;text-align:left;margin-left:0">
                        <!-- <hr style="width: 75%; text-align: left; margin-left: 0;margin-top: 2px;margin-bottom: 2px;border: 2px solid;"></hr> -->
                        <div class="ml-3 form-check">
                            <label class="form-check-label termsChecklabel" for="check7">
                              <a style="text-decoration : none"
                              href="../assets/docs/Sensformer_AcceptableUsePolicy v1.0.pdf"
                              target="_blank">
                          Acceptable Use Policy
                        </a>
                      </label>
                        </div>
                        <hr style="width:50%;text-align:left;margin-left:0">
                        <!-- <hr style="width: 75%; text-align: left; margin-left: 0;margin-top: 2px;margin-bottom: 2px;border: 2px solid;"></hr> -->
                        <div class="ml-3 form-check">
                            <label class="form-check-label termsChecklabel" for="check7">
                              <a style="text-decoration : none"
                              href="../assets/docs/Data_Privacy_Terms_alt.pdf"
                              target="_blank">
                          Data Privacy
                        </a>
                      </label>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</div>
<div class="copyright">
    &#169; Siemens Energy is a trademark licensed by Siemens AG. © Siemens Energy, 2020 - 2021
</div>