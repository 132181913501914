import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TermsAndConditionsService {

  constructor(private http: HttpClient) { }

  getCurrentLocation(lat: any,lng: any): Observable<any> {
    //const urlLocation = 'https://secure.geonames.net/childrenJSON?geonameId=' + data + '&username=sensproducts';
    const urlLocation = 'http://api.geonames.org/extendedFindNearbyJSON?lat='+'-37.81'+'&lng='+'144.78'+'&username=sensformer';
    return this.http.get<any>(urlLocation);
  }
}
