import { Component } from '@angular/core';
import {TermsAndConditionsService} from '../app/custom_services/terms-and-conditions.service'
import { from, Observable } from 'rxjs';
import { literalArr } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'SensformerTermsAndConditions';
  continentName:String;
  isData=false;
  showAll=false;

  latitude;
  longitude;

  constructor(private termsAndConditions :TermsAndConditionsService){

  }

  ngOnInit() {
   
  }
}
